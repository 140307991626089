import { makeStyles } from '@material-ui/core/styles';


export const useStyles =  makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    marginTop: 30,
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '84%',
  },
  modalContentContainer: {
    display: 'flex',
    width: '100% !important',
    justifyContent: 'space-between',
  },
  optionsContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  placesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
    width: '100%',
  },
  addTagButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,
  },
  listsWrapper: {
    display: 'flex',
    flexDirection: 'column',

    '& h3': {
      marginBottom: 30,
    },
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    color: 'rgb(80, 129, 115) !important',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 220,
    marginRight: 50,
  },
  productItem: {
    marginRight: 10,
  },
  secondaryRoot: {
    right: '-300px !important',
  },
  textField: {
    width: '100%',
    marginBottom: 40,
  },
  formDropdown: {
    paddingBottom: 40,
  },
  '.rsUploader': {
    marginTop: '30px',
  },
  switchConteiner: {
    display: 'flex',
    alignItems: 'center',
  },
  uploaderContainer: {
    marginTop: '10px',
    display: 'flex',
  },
  openingHoursPerDayContainer: {
    display: 'flex',
    justifyContent: 'space-around !important',
    height: '50px',
  },
  fromToField: {
    width: '40%'
  },
  editorWrapper: {
    marginBottom: '24px',
    marginTop: '16px',
    border: '1px solid #ddd',
    paddingBottom: 64,
    height: 260,
    maxHeight: 260,
  },
  editor: {
    cursor: 'text',
    padding: '8px',
    height: 220,
  },
  editorWrapperShort: {
    marginBottom: '24px',
    marginTop: '16px',
    border: '1px solid #ddd',
    paddingBottom: 64,
    height: 160,
    maxHeight: 160,
  },
  editorShort: {
    cursor: 'text',
    padding: '8px',
    height: 120,
  },
}));
