import React, { useEffect, useState } from 'react';

import {
  MenuItem,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { SimpleModal, RegularList } from '../../components';
import {
  getTipsData,
  createNewTipData,
  deleteTipData,
  activateTipData,
} from '../../data/controllers/tips';
import { COUNTRIES } from '../../constants';
import { Toolbar } from './Toolbar';

import { useStyles } from './tips.style';

const DEFAULT_LANGUAGE = 'pl';

export const Tips = () => {
  const [tips, setTips] = useState([]);
  const [newTip, setNewTip] = useState({ priority: 1, language: DEFAULT_LANGUAGE, active: 'no' });
  const [newTipModalVisibility, setNewTipModalVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [editorDescriptionState, setEditorDescriptionState] = useState(() => EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const classes = useStyles();

  const handleEditorDescriptionContentChange = content => {
    setEditorDescriptionState(content);
    handleChangeDescription(convertToRaw(editorDescriptionState.getCurrentContent()), false);
  };

  const handleEditorContentChange = content => {
    setEditorState(content);
    handleChangeDescription(convertToRaw(editorState.getCurrentContent()), true);
  };

  const formatDescription = originalDescription => {
    if(!originalDescription) {
      return '';
    }

    let description = '';
    try {
      description = JSON.parse(originalDescription);
    } catch(err) {}

    let formattedDescription = '';
    if(description.blocks) {
      description.blocks.forEach(block => {
        formattedDescription += `${block.text} `;
      });
    }

    if(typeof formattedDescription === 'string') {
      formattedDescription = formattedDescription
        .replace(/<\/?[^>]+(>|$)/g, ' ')
        .substr(0, 320) + (formattedDescription.length > 320 ? '...' : '');
    }

    return formattedDescription;
  };

  const getTips = async country => {
    const { data: { tips } }  = await getTipsData(country);
    tips.forEach(tip => {
      tip.name = tip.title;
    });
    await setTips(tips);
  }

  useEffect(() => {
    getTips(DEFAULT_LANGUAGE);
  }, []);

  useEffect(() => {
    getTips(language);
  }, [
    language,
  ]);

  const handleUpdateTip = tip => {
    const foundTip = tips.find(t => t.id === tip.id);
    foundTip.active = (foundTip.active === true) ? 'yes' : 'no';

    setNewTip(foundTip);
    setNewTipModalVisibility(true);

    const createContent = text => {
      const contentHTML = draftToHtml(JSON.parse(text));
      const blocksFromHTML = convertFromHTML(contentHTML);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      return content;
    };

    try {
      const contentShortDescription = createContent(foundTip.short_description);
      setEditorState(EditorState.createWithContent(contentShortDescription));

      const contentDescription = createContent(foundTip.description);
      setEditorDescriptionState(EditorState.createWithContent(contentDescription));
    } catch(e) {
      setEditorState(() => EditorState.createEmpty());
      setEditorDescriptionState(() => EditorState.createEmpty());
    }
  }

  const handleChangeDescription = (description, short = false) => {
    if(short) {
      setNewTip({
        ...newTip,
        short_description: description,
      });
    } else {
      setNewTip({
        ...newTip,
        description,
      });
    }
  };

  const handleRegularFieldChange = ({ target: { id: inputId, value }}) => {
    setNewTip({
      ...newTip,
      [inputId]: value,
    });
  }

  const setCleanTipForm = () => {
    setEditorState(() => EditorState.createEmpty());
    setNewTip({ priority: 1, language: DEFAULT_LANGUAGE, active: 'no', title: '' });
  };

  const saveNewTip = async () => {
    newTip.active = newTip.active === 'yes';

    if(!newTip.title || !newTip.short_description || !newTip.description || !newTip.language) {
      setError('Please fill the whole tip data')
    } else {
      newTip.short_description = typeof newTip.short_description !== 'string' ? JSON.stringify(newTip.short_description) : newTip.short_description;
      newTip.description = typeof newTip.description !== 'string' ? JSON.stringify(newTip.description) : newTip.description;
      await createNewTipData(newTip);

      setNewTipModalVisibility(false);
      getTips(language);
      setCleanTipForm();
    }
  }

  const handleActivateTip = async(e, tipId) => {
    await activateTipData(tipId);
    await getTips(language);
  };

  const handleDeleteTip = async id => {
    await deleteTipData({ id });
    await getTips(language);
  };

  const renderLanguagesOptions = (short = false) => {
    return COUNTRIES.map(({ name, shortcut }, idx) => {
      return <MenuItem
        key={`${shortcut}_${idx}`}
        value={shortcut}
      >
        {short ? shortcut : name}
      </MenuItem>
    });
  };

  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        disableScrollLock
        isOpened={newTipModalVisibility}
        onClose={() => setNewTipModalVisibility(false)}
        title="Add new tip"
        submit={saveNewTip}
        submitButtonText="Save"
        saveButtonPositionRelative
      >
        <div className={classes.modalContentContainer}>
          <div className={classes.column} style={{
            paddingTop: 24
          }}>
            <TextField
              label="title"
              className={classes.textField}
              id="title"
              size="small"
              onChange={handleRegularFieldChange}
              value={newTip.title || ""}
            />

            <Typography
              variant="body1"
              sx={{ paddingTop: '16px', color: 'rgba(0, 0, 0, 0.54)' }}
            >
              Short description
            </Typography>
            <Typography
              variant="caption"
            >
              For new line use `ctrl+Enter`. For new paragraph use `Enter`
            </Typography>
            <Editor
              toolbar={Toolbar}
              editorState={editorState}
              wrapperClassName={classes.editorWrapperShort}
              editorClassName={classes.editorShort}
              onEditorStateChange={handleEditorContentChange}
              handleBeforeInput={val => {
                const textLength = editorState.getCurrentContent().getPlainText().length;
                if (val && textLength >= 150) {
                    return 'handled';
                 }
                 return 'not-handled';
               }}
              handlePastedText={val => {
                 const textLength = editorState.getCurrentContent().getPlainText().length;
                 return ((val.length + textLength) >= 150);
                }}
            />

            <Typography
              variant="body1"
              sx={{ paddingTop: '16px', color: 'rgba(0, 0, 0, 0.54)' }}
            >
              Description
            </Typography>
            <Typography
              variant="caption"
            >
              For new line use `ctrl+Enter`. For new paragraph use `Enter`
            </Typography>
            <Editor
              toolbar={Toolbar}
              editorState={editorDescriptionState}
              wrapperClassName={classes.editorWrapper}
              editorClassName={classes.editor}
              onEditorStateChange={handleEditorDescriptionContentChange}
            />
            {/* <ReactQuill
              theme="snow"
              id="description"
              placeholder="description"
              value={newTip.description || ""}
              onChange={handleChangeDescription}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['clean']
                ],
              }}
              formats={[
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
              ]}
              style={{
                paddingBottom: 64,
                height: 280,
                maxHeight: 280,
                overflow: 'auto',
              }}
            /> */}
            {/* <TextField
              label="description"
              id="description"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newTip.description || ""}
            /> */}
            <TextField
              label="priotiry (the higher the more important)"
              id="priority"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newTip.priority || ""}
            />
            <div className={classes.formDropdown}>
              <Tooltip title={'Language'}>
                <FormControl>
                  <Typography
                    variant="body"
                  >
                    Language
                  </Typography>
                  <Select
                    className={classes.categorySelectInput}
                    labelId="language-label"
                    value={newTip.language || DEFAULT_LANGUAGE}
                    onChange={e => setNewTip({ ...newTip, language: e.target.value })}
                  >
                    {renderLanguagesOptions(true)}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
            <div className={classes.formDropdown}>
              <Tooltip title={'Active'}>
                <FormControl>
                  <Typography
                    variant="body"
                  >
                    Active
                  </Typography>
                  <Select
                    className={classes.categorySelectInput}
                    labelId="active-label"
                    value={newTip.active}
                    onChange={e => setNewTip({ ...newTip, active: e.target.value })}
                  >
                    <MenuItem key="active_yes" value={'yes'}>
                      yes
                    </MenuItem>
                    <MenuItem key="active_no" value={'no'}>
                      no
                    </MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          </div>
        </div>
        {!!error ? <p style={{ color: 'red' }}>{error}</p> : null}
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.placesHeader}>
            <h3>TIPS</h3>
            <Typography
              variant="h6"
              onClick={() => {
                setCleanTipForm();
                setNewTipModalVisibility(true);
              }}
              className={classes.addTagButton}
            >
              <Add />Tip
            </Typography>
          </div>
        </div>
        <div>
          <FormControl>
            <InputLabel id="language">Language</InputLabel>
            <Select
              labelId="language"
              value={language}
              onChange={e => setLanguage(e.target.value)}
            >
              {renderLanguagesOptions()}
            </Select>
          </FormControl>
        </div>
        {tips.length ? (
          <div className={classes.listsWrapper}>
            <div className={classes.list}>
              <RegularList
                items={tips?.map(tip => ({
                  ...tip,
                  short_description: formatDescription(tip.short_description),
                  description: formatDescription(tip.description),
                  active: tip.active === 'yes' || tip.active === true,
                }))}
                areItemsEditable={true}
                areItemsActivable={true}
                handleItemActivityChange={handleActivateTip}
                handleDeleteItem={handleDeleteTip}
                handleUpdateItem={handleUpdateTip}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
