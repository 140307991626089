import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  MenuItem,
  ListItemSecondaryAction,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  Add,
  Delete,
  Edit,
  Label,
} from '@material-ui/icons';


import { SimpleModal } from '../../components';
import {
  assignTagCategory,
  changeTagActivityData,
  createNewTagCategoryData,
  createNewTagData,
  deleteTagData,
  editTagData,
  getTagsData,
  getTagsCategoriesData,
} from '../../data/controllers';

import { useStyles } from './tags.style';


export const Tags = () => {
  const [tags, setTags] = useState([]);
  const [tagsCategories, setTagsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [newTagModalVisibility, setNewTagModalVisibility] = useState(false);
  const [newTagCategoryModalVisibility, setNewTagCategoryModalVisibility] = useState(false);
  const [newTagName, setNewTagName] = useState(null);
  const [newTagCategoryName, setNewTagCategoryName] = useState(null);
  const [editableFields, setEditableFields] = useState([]);

  const classes = useStyles();


  const getTags = async () => {
    const { data: { tags } } = await getTagsData();
    await setTags(tags);
  }

  const getTagsCategories = async () => {
    const { data: { categories } } = await getTagsCategoriesData();
    await setTagsCategories(categories);
  }

  useEffect(() => {
    getTags(tags);
    getTagsCategories();
  }, []);

  const createNewTag = async() => {
    await createNewTagData({
      name: newTagName,
      order: tags.length,
    });

    await getTags();
    await setNewTagModalVisibility(false);
  }

  const createNewTagCategory = async() => {
    await createNewTagCategoryData({
      name: newTagCategoryName,
      order: tags.length,
    });

    await getTagsCategories();
    await setNewTagCategoryModalVisibility(false);
  }

  const handleDeleteTag = async id => {
    await deleteTagData({ id });
    await getTags();
  }

  const handleUpdateTag = async tag => {
    const updatedTag = tags.map(tagItem => {
      if(tagItem.id === tag.id) {
        return {
          ...tagItem,
          editable: true,
        };
      }

      return {
        ...tagItem,
        editable: false,
      }
    });

    setEditableFields(updatedTag);
  }

  const handleEditKeyPress = async (e, tag, index) => {
    if(e.key === 'Enter'){
      await editTagData({
        id: tag.id,
        name: e.target.value,
      });

      editableFields[index] = {
        ...tag,
        editable: false,
      };

      setEditableFields(editableFields);
      await getTags();
    }
  }

  const handleActivityChange = async (e, id) => {
    await changeTagActivityData({
      id,
      active: e.target.checked,
    });

    await getTags();
  }

  const handleCategoriesChange = async(e, tagId) => {
    setSelectedCategories({
      ...selectedCategories,
      [tagId]: e.target.value,
    });

    await assignTagCategory({ tagId, categoryId: e.target.value });
  };

  const renderCategoriesOptions = () => {
    return tagsCategories?.map(({name, id}) => <MenuItem value={id}>{name}</MenuItem>);
  };

  const renderCategories = tag => {
    return (
      <Tooltip title={'Category'}>
        <FormControl>
          <Select
            className={classes.categorySelectInput}
            labelId="category-label"
            value={selectedCategories[tag.id] || tag.category_id}
            onChange={e => handleCategoriesChange(e, tag.id)}
          >
            {renderCategoriesOptions()}
          </Select>
        </FormControl>
      </Tooltip>
    )
  };

  return (
    <Fragment>
      <SimpleModal
        isOpened={newTagCategoryModalVisibility}
        onClose={() => setNewTagCategoryModalVisibility(false)}
        title="Create new tag category"
        showInitSubmitButton={false}
      >
        <TextField
          label="Name"
          onChange={e => setNewTagCategoryName(e.target.value)}
        />
        <Button variant="contained" onClick={createNewTagCategory} className={classes.createButton}>
          Create
        </Button>
      </SimpleModal>
      <SimpleModal
        isOpened={newTagModalVisibility}
        onClose={() => setNewTagModalVisibility(false)}
        title="Create new tag"
        showInitSubmitButton={false}
      >
        <TextField
          label="Name"
          onChange={e => setNewTagName(e.target.value)}
        />
        <Button variant="contained" onClick={createNewTag} className={classes.createButton}>
          Create
        </Button>
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.tagsHeaderContainer}>
          <div className={classes.tagsHeader}>
            <Typography
              variant="h6"
              onClick={() => setNewTagCategoryModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />TAG CATEGORY
            </Typography>
          </div>
          <div className={classes.tagsHeader}>
            <Typography
              variant="h6"
              onClick={() => setNewTagModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />TAG
            </Typography>
          </div>
        </div>
        <div className={classes.listsWrapper}>
          <List>
            {tags.map((tag, index) => (
              <ListItem
                ContainerComponent="li"
                key={`${tag.name}_${index}`}
              >
                <ListItemIcon>
                  <Label />
                </ListItemIcon>

                {
                  editableFields[index]?.editable
                    ? <TextField
                        id="standard-basic"
                        onKeyPress={e => handleEditKeyPress(e, tag, index)}
                        defaultValue={tag.name}
                      />
                    : <div className={classes.tagTextContainer}>
                        <ListItemText primary={tag.name} className={classes.tagItem}/>
                        <span className={classes.recipesCount}>({tag.recipesCount})</span>
                      </div>
                }

                {renderCategories(tag)}
                <ListItemSecondaryAction className={classes.secondaryRoot}>
                  <Tooltip title={!!tag.active ? 'Activated' : 'Disactivated'}>
                    <Checkbox
                      checked={!!tag.active}
                      onChange={e => handleActivityChange(e, tag.id)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      className={classes.checkbox}
                    />
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleUpdateTag(tag)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => handleDeleteTag(tag.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {/*<Categories categories={tagsCategories} getCategories={getTagsCategories} />*/}
        </div>
      </div>
    </Fragment>
  );
}
