import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  Input,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@material-ui/core';
import { Link } from 'react-router-dom';


import { useHistory } from 'react-router-dom';

import { useStyles } from './dashboard.style';
import {
  getRecipeData,
  getListsData,
  getTagsData,
  saveListsData,
  saveTagsData,
  saveStatusData,
  getTagsCategoriesData,
  saveOnboardingStatusData,
} from '../../data/controllers';
import { RECIPES_LANG_OPTIONS, RECIPES_STATUS_OPTIONS } from '../../constants/recipes';
import { ColorCheckbox } from '../../components/Checkbox';



export const Dashboard = () => {
  const [recipe, setRecipe] = useState(null);
  const [categories, setCategories] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [lists, setLists] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  const [selectedTags, setSelectedTags] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [onboardingChecked, setOnboardingChecked] = useState('pending');
  const [offset, setOffset] = useState(0);
  const [recipesLang, setRecipesLang] = useState('PL');

  const classes = useStyles();
  const history = useHistory();


  const getRecipe = async (offset, selectedStatus, recipesLang) => {
    const { data: { recipeDetails: recipe, totalCount } } = await getRecipeData(offset, selectedStatus, recipesLang);

    await setSelectedLists(recipe?.listsIds || []);
    await setSelectedTags({ ...recipe?.categoryTagMap })
    await setRecipe(recipe);
    await setTotalCount(totalCount);
    await setOnboardingChecked(recipe?.in_onboarding);
  };

  const getLists = async lang => {
    const { data: { lists } } = await getListsData(lang);

    await setLists(lists);
    await setTotalCount(totalCount);
  };

  const getTags = async() => {
    const { data: { tags } } = await getTagsData();

    await setTags(tags);
  };

  const handleListsChange = listId => {
    const lists = selectedLists.includes(listId)
      ? selectedLists.filter(listIdItem => listIdItem !== listId)
      : Array.from(new Set([
        ...selectedLists,
        listId,
      ]));

    setSelectedLists(lists);
  };

  const handleTagsChange = (e, categoryId) => {
    setSelectedTags({
      ...selectedTags,
      [categoryId]: e.target.value,
    });
  };

  const getTagsCategories = async() => {
    const { data: { categories } } = await getTagsCategoriesData();
    await setCategories(categories);
  };

  const handleStatusChange = async event => {
    setSelectedStatus(event.target.value);

    setOffset(0);
    await getRecipe(0, event.target.value, recipesLang.toLowerCase());
  };

  const handleRecipeLangChange = async event => {
    setRecipesLang(event.target.value);
    setOffset(0);

    await getLists(event.target.value);
    await getRecipe(0, selectedStatus, event.target.value.toLowerCase());
  };

  const renderListsTiles = () => {
    return lists?.map((list, idx) => {
      return (
        <Button
          key={list + idx}
          onClick={() => handleListsChange(list.id)}
          style={selectedLists.includes(list.id) ? { backgroundColor: 'rgb(139,158,162)', color: '#FFF' } : {}}
          >
          {list.name}
        </Button>
      );
    });
  };

  const renderTagsSelectOptions = category => {
    return tags?.map(tag => {
      if(tag.category && tag.category.id === category.id) {
        return <MenuItem value={tag.id}>{tag.name}</MenuItem>
      }

      return null;
    }).filter(Boolean);
  };

  const renderTagsSelect = () => {
    return categories?.map((category, idx) => {
      return (
        <FormControl>
          <Select
            labelId={`${category}_${idx}`}
            value={selectedTags[category.id] || []}
            onChange={e => handleTagsChange(e, category.id)}
            className={classes.tagsSelect}
            displayEmpty
            multiple
            input={<Input />}
          >
            {renderTagsSelectOptions(category)}
          </Select>
          <FormHelperText style={{ margin: '0 0 10px 0' }}>{category.name.replaceAll('_', ' ')}</FormHelperText>
        </FormControl>
      )});
  };


  useEffect(() => {
    renderTagsSelect();
    renderListsTiles();
  }, [
    selectedTags,
    selectedLists,
  ]);

  const renderStatusOptions = () => {
    return RECIPES_STATUS_OPTIONS?.map((option, idx) => {
      return (
        <MenuItem
          key={`${option.id}_${idx}`}
          value={option.name}
          name={option.name}
        >
          {option.name}
        </MenuItem>
      );
    });
  }

  const renderRecipeLangOptions = () => {
    return RECIPES_LANG_OPTIONS?.map((lang, idx) => {
      return (
        <MenuItem
          key={`${lang.id}_${idx}`}
          value={lang.name}
          name={lang.name}
        >
          {lang.name}
        </MenuItem>
      );
    });
  }


  const saveRecipe = async() => {
    await saveListsData({ recipeId: recipe.id, listsIds: selectedLists });
    await saveTagsData({
      recipeId: recipe.id,
      tagsIds: selectedTags,
    });
    await saveOnboardingStatusData({
      inOnboarding: onboardingChecked,
      recipeId: recipe.id,
    });

    await getRecipe(offset, selectedStatus, recipesLang);
  }

  const setAdminStatus = async status => {
    await saveStatusData({
      status,
      recipeId: recipe.id,
    });

    await getRecipe(offset, selectedStatus, recipesLang.toLowerCase());
  };

  const handleArrowClick = async direction => {
    setSelectedTags({});

    if(direction === 'left' && offset > 0) {
      setOffset(offset - 1);

      await getRecipe(offset - 1, selectedStatus, recipesLang.toLowerCase());
    } else if (direction === 'left' && offset === 0) {
      setOffset(offset);
    } else {
      setOffset(offset + 1);

      await getRecipe(offset + 1, selectedStatus, recipesLang.toLowerCase());
    }
  };

  const handleBackToPreviousRecipe = async() => {
    await getRecipe(offset - 1, selectedStatus, recipesLang);
    setOffset(offset - 1);
  }

  useEffect(() => {
    getRecipe(0, 'pending', 'pl');
    getLists('pl');
    getTags();
    getTagsCategories();
  }, [
    history,
  ]);

  const handleOnboardingCheckboxChange = e => {
    setOnboardingChecked(e.target.checked);
  };

  const renderRecipe = () => {
    return (
      <Fragment>
        <Card className={classes.root}>
          <Card className={classes.cardContainer}>
            <Button className={classes.nextButton}>Next</Button>
            {offset > 0 &&
            <Button
              className={classes.previousButton}
              onClick={() => handleArrowClick('left')}>Prev</Button>}

            {offset !== totalCount &&
            <Button
              className={classes.nextButton}
              onClick={() => handleArrowClick('right')}>Next</Button>}
            <div className={classes.tagsTilesSection}>
              <h4>TAGS</h4>
              {renderTagsSelect()}
              <FormControlLabel
                control={
                  <ColorCheckbox
                    checked={onboardingChecked}
                    onChange={handleOnboardingCheckboxChange}
                    inputProps={{'aria-label': 'primary checkbox'}}
                  />
                }
                label="Onboarding"
                style={{ marginTop: '30px'}}
              />
            </div>

            <div className={classes.listsTilesSection}>
              <h4>LISTS</h4>
              {renderListsTiles()}
            </div>

            <CardContent>
              {recipe?.uuid ? (
                <iframe
                  className={classes.recipeIframe}
                  src={`${process.env.REACT_APP_WEB_BASE_URL}/${recipe.user_nick}/${recipe.slug}`}
                  title={recipe?.title}>
                </iframe>
              ) : null}
            </CardContent>
            <div className={classes.buttonSection}>
              <Button variant="contained" className={classes.saveButton} onClick={saveRecipe}>
                Save
              </Button>
              <Button
                variant="contained"
                className={classes.acceptButton}
                onClick={() => setAdminStatus('accepted')}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                onClick={() => setAdminStatus('rejected')}
                className={classes.rejectButton}
              >
                Reject
              </Button>
            </div>
          </Card>
        </Card>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className={classes.filtersBar}>
        <h4>FILTERS</h4>
        <FormControl className={classes.formControl}>
          <Select
            labelId="statusLabel"
            value={selectedStatus || null}
            onChange={handleStatusChange}
            input={<Input />}
          >
            {renderStatusOptions()}
          </Select>
          <FormHelperText>Status</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Select
            labelId="recipeLangLabel"
            value={recipesLang || null}
            onChange={handleRecipeLangChange}
            input={<Input />}
          >
            {renderRecipeLangOptions()}
          </Select>
          <FormHelperText>Language</FormHelperText>
        </FormControl>
      </div>
      <div className={classes.root}>
        {
          recipe && Object.entries(recipe).length ? (
            <Fragment>
              <Grid container spacing={3}>
                {renderRecipe()}
              </Grid>
            </Fragment>
          ) : (
            <div className={classes.noRecipesAvailable}>
              <p>No recipes available. Consider to change filters or...</p>
              <Button className={classes.goBack} onClick={handleBackToPreviousRecipe}>Go back to previous recipe</Button>
            </div>
          )
        }
      </div>
      <Link to="/recipes/add">
        <Button className={classes.addRecipe}>+ recipe</Button>
      </Link>
    </Fragment>
  );
}
